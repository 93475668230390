.sidebarcontent {
    background: #ffffff;
    height: 100%;
    height: 100vh;
    min-width: 19vw;
    @media(min-width: 800px) {
        width: 19vw;
    }
    &__item {
        display: flex;
        flex-direction: row;
        padding: 1em;
        align-items: center;
        padding: 1.4em 1.6em;
        color: #000000;
        &__icon {
            max-width: 1em;
            display: inherit;
            justify-content: space-between;
            align-items: center;
            flex: 1;
        }
        &__content {
            margin: 0 .5em;
        }
        &--header {
            padding: 1.4em 1.6em;
            background-color: #f2f2f2;
        }
    }
}