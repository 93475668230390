@import 'fonts';
@import 'variables';
@import 'responsive';

* {
    font-family: font-regular;
    font-size: $fontSizeBase;
}

button {
    border-radius: $borderRadiusBase;
    box-shadow: $boxShadowBase;
}

.app > div > div {
    background-color: $colorBackgroundApp;
}
.content {
    &__success {
        height: 70vh;
        display: flex;
        align-items: center;
        justify-content: center;
        &--message {
            text-align: center;
            > svg {
                font-size: 4rem;
                margin-bottom: 1rem;
            }
            > span {
                font-size: 20px;
                font-weight: 500;
            }
        }
    }
    &--deleted {
        &--message {
            > svg {
                color: $colorDanger;
            }
        }
    }
    &--created {
        &--message {
            > svg {
                color: $colorSuccess;
            }
        }
    }
} 