@import '../../styles/variables';
@import '../../styles/responsive';

.auditschedule {
  &__loading-overlay {
    &--false {
      display: none;
    }

    &--true {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      position: absolute;
      width: 100%;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: rgba(142, 142, 142, 0.5);
      z-index: 1;
      min-height: 100vh;
    }
  }

  &__content {
    &__row {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 0 10px;
      @media #{$s360} {
        flex-direction: column;
      }
    }

    &__item {
      &.col {
        flex: 1 1;
        width: 100%;
        padding: 0 10px;
      }

      margin: 1rem 0;
      padding: 0 20px;
    }
  }

  &__due-date {
    &__label {
      font-size: 14px;
      color: rgba(24, 24, 24, 0.4);
      margin-bottom: 0.5em;
    }

    &__picker {
      .react-datepicker-wrapper {
        display: block;
      }

      input {
        outline: none;
        width: 100%;
        font-size: 0.9em;
        border-radius: $borderRadiusSecondary;
        box-shadow: $boxShadowBase;
        border: none;
        line-height: 16px;
        padding: 15px 10px;
        margin: 0;
      }
    }
  }

  &__viewBy {
    padding: 0;
    margin-top: 1em;

    &__dropdown {
      margin-top: 1rem;
      padding: 0;

      > div {
        > div {
          border: none !important;
          box-shadow: $boxShadowBase;
          border-radius: $borderRadiusSecondary;

          svg {
            color: $colorBase;
          }
        }
      }
    }

    > .switch-field {
      input:checked + label {
        background-color: $colorBase;
      }

      > label {
        color: $colorTextGhost;
        background-color: $colorWhite;
        border: none;
        box-shadow: $boxShadowBase;
        height: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        border-radius: 0;
        font-size: $fontSizeBase;
      }
    }
  }

  &__item {
    padding: 1em;

    &--title {
      font-size: 1.2em;
    }
  }

  &__content {
    margin-bottom: 2em;
  }
}
