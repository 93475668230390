@import '../../styles/variables';
@import '../../styles/responsive';

.task {
    &__loading-overlay {
        &--false {
            display: none;
        }
        &--true {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            position: absolute;
            // position: sticky;
            // height: 100vh;
            width: 100%;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: rgba(142, 142, 142, 0.5);
            z-index: 1;
            min-height: 100vh;
        }
    }
    &__content {
        // margin-bottom: 2em;
    }
    &__review-section {
        // display: none;
    }
    &__review-tags {
        display: none;
        flex-direction: row;
        align-items: center;
        margin-bottom: 1em;
    }
    &__item {
        padding: 1rem 2rem;
        &--content {
            min-height: 3rem;
            box-shadow: $boxShadowBase;
            border-radius: $borderRadiusSecondary;
            line-height: 3rem;
            padding-left: 1rem;
        }
        &--title {
            font-size: 1.2em;
            padding-bottom: 0.5em;
            &__content {
                height: 3rem;
                box-shadow: $boxShadowBase;
                border-radius: $borderRadiusSecondary;
                line-height: 3rem;
                padding-left: 1rem;
            }
            &__delete {
                .btn {
                    min-width: 0;
                }
            }
        }
        &--createdAt,
        &--due-date,
        &--store {
            &__title {
                font-weight: 500;
                margin-bottom: 0.5rem;
                color: $colorTextGhost;
                font-size: 14px;
            }
        }
        &--reviewRequested {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 0.5em;
        }
        &--reviewCompleted {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 0.5em;
        }
        &--assignTask {
            // display: flex;
            // flex-direction: row;
            // justify-content: space-between;
            // align-items: flex-start;
            padding-bottom: 0.3em;
            @include screenMax576 {
            }
            &__dropdown {
                margin-top: 1rem;
                > div {
                    border: none;
                    border-radius: $borderRadiusSecondary;
                    > div {
                        text-align: left;
                        height: 100%;
                        border: none !important;
                        // box-shadow: $boxShadowBase;
                        // border-radius: $borderRadiusSecondary;
                        svg {
                            color: $colorBase;
                        }
                    }
                }
            }
            &__input {
                text-align: right;
            }
            &__button {
                font-size: 0.8em;
                padding: 0.3em 0;
                box-shadow: none;
                background: none;
                min-width: 0;
                min-height: 0;
                color: #27aee3;
            }
            &__name {
                font-size: 0.9em;
            }
        }
        &--taskassign {
            &__cta {
                margin-top: 1em;
            }
        }
        // &--store {
        //     padding: 0.3em 1em;
        //     &__content {
        //         background-color: #27aee3;
        //         color: #ffffff;
        //         font-size: 0.7em;
        //         border-radius: 4px;
        //         padding: 4px 6px;
        //     }
        // }
        &--description {
            &__content {
                height: fit-content;
                box-shadow: $boxShadowBase;
                padding: 1rem;
                min-height: 6rem;
            }
        }
        &--delete {
            padding: 1rem;
            > button.btn {
                width: 100%;
                background-color: $colorDanger;
                color: $colorWhite;
            }
        }
        &--image {
            &__title {
                margin-bottom: 1rem;
                font-size: 1.2em;
                // border-bottom: 2px solid #e2e2e2;
                padding-bottom: 0.5em;
            }
            &__empty {
                color: $colorTextGhost;
                font-size: 14px;
            }
        }
    }
    &__cta {
        position: fixed;
        bottom: 0;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #f7f8f9;
        // box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
        @media (min-width: 800px) {
            width: 81vw;
            right: 0;
        }
        @media (max-width: 799px) {
            left: 0;
        }
        &__item {
            width: 50%;
            text-align: center;
            @include screenMax576 {
                width: 100%;
                padding: 0.5rem 1rem;
            }
            &--headquarter {
                width: 100%;
            }
            &--audit {
                width: 100%;
            }
            &--manual,
            &--promotion {
            }
        }
        &__button {
            border: 1px solid #f2f2f2;
            background: $colorBase;
            box-shadow: none;
            border-radius: $borderRadiusBase;
            width: 100%;
            font-size: 1em;
            &--highlight {
                border-left: none;
                background-color: $colorBase;
                color: #ffffff;
            }
            &--highlight-dark {
                border-left: none;
                color: #ffffff;
                background-color: $colorBase;
            }
            &--add-activity {
                background-color: $colorBase;
            }
        }
    }
}

.addactivitymodal {
    z-index: 1002;
    &__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: baseline;
        margin-bottom: 2em;
    }
    &__title {
        margin: 0.3em;
        font-size: 1.3em;
    }
    &__close {
        max-width: 18px;
        margin: 0.3em;
    }
    &__content {
        margin: 1em 0;
    }
    &__file {
        &__button {
            box-shadow: none;
            background-color: $colorWhite;
            color: $colorBase;
            border: 2px solid $colorBase;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: $borderRadiusBase;
            font-weight: 600;
            min-height: 2rem;
            margin: 1.5em 0;
        }
        &__preview {
            max-width: 100%;
            margin: 0 auto;
        }
    }
    &__cta {
        margin-top: 1em;
        &__button {
            box-shadow: none;
            background-color: $colorBase;
            color: #ffffff;
        }
    }
}

.activity {
    &__title {
        margin-bottom: 1.3em;
        font-size: 1.2em;
        border-bottom: 2px solid #e2e2e2;
        padding-bottom: 0.5em;
    }
    &__content {
        &--empty {
            color: #474747;
            font-size: 0.8em;
        }
    }
}

.comments {
    &__add {
        margin: 1em 0;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        &--button {
            margin-top: 1rem;
            width: 100%;
            background-color: $colorWhite;
            color: $colorBase;
            border: 2px solid $colorBase;
            font-size: $fontSizeBase;
            font-weight: 600;
            min-height: 2rem;
        }
        &--comment {
            box-shadow: 0px 10px 20px rgba(120, 121, 147, 0.15);
            > input,
            textarea {
                border-radius: $borderRadiusBase;
                box-shadow: 0px 10px 20px rgba(120, 121, 147, 0.15);
                border: none;
                &:focus {
                    box-shadow: none;
                }
                &:focus + span,
                &:focus + span::before,
                &:focus + span::after {
                    box-shadow: none;
                    border: none;
                    color: $colorBase;
                }
            }
        }
    }

    .activity__title {
        margin-bottom: 0.5em;
    }
}

.comment {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0.5em 0;
    &__header {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin: 0.5em 0;
        width: 100%;
        &__left {
            padding: 0.3em 0.2em;
            display: flex;
            align-items: center;
        }
        &__right {
            padding: 0.3em 0.2em;
            flex-grow: 1;
            &__top {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
            }
            &__bottom {
            }
        }
    }
    &__pic {
        width: 2.2em;
        margin-right: 0.3em;
    }
    &__time {
        color: #c4c4c4;
        font-size: 0.8em;
        margin-top: 0.1em;
    }
    &__role {
        color: #c4c4c4;
        font-size: 0.8em;
    }
    &__body {
        margin-top: 0.5em;
        color: #53668a;
        font-size: 0.9em;
        &__content {
            font-family: 'Poppins', sans-serif;
            white-space: pre-wrap;
            word-break: break-word;
            max-width: 30em;
            line-height: 1.4;
        }
    }
}

input[type='file'] {
    display: none;
}

.tg-list {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
    display: flex;
    align-items: center;
}

.tg-list-item {
    list-style: none;
    margin: 0;
    padding: 0;
    margin: 0 2em;
}

.tgl {
    display: none;

    // add default box-sizing for this scope
    &,
    &:after,
    &:before,
    & *,
    & *:after,
    & *:before,
    & + .tgl-btn {
        box-sizing: border-box;
        &::selection {
            background: none;
        }
    }

    + .tgl-btn {
        outline: 0;
        display: block;
        width: 2em;
        height: 1em;
        position: relative;
        cursor: pointer;
        user-select: none;
        &:after,
        &:before {
            position: relative;
            display: block;
            content: '';
            width: 50%;
            height: 100%;
        }

        &:after {
            left: 0;
        }

        &:before {
            display: none;
        }
    }

    &:checked + .tgl-btn:after {
        left: 50%;
    }
}

// themes
.tgl-light {
    + .tgl-btn {
        background: #f0f0f0;
        border-radius: 2em;
        padding: 2px;
        transition: all 0.4s ease;
        &:after {
            border-radius: 50%;
            background: #fff;
            transition: all 0.2s ease;
        }
    }

    &:checked + .tgl-btn {
        background: #9fd6ae;
    }
}

.taskdonemodal {
    position: relative;
    &__overlay {
        background-color: rgba(142, 142, 142, 0.5);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    &__item {
        padding: 0 1em;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 1em;
        box-shadow: none;
        border: none;
        background-color: #ffffff;
        font-size: 1em;
        min-width: 0;
        min-height: 2em;
        border-radius: 0;
        width: 100%;
        &__icon {
            max-width: 1em;
            display: flex;
            align-items: center;
            margin-right: 0.5em;
        }
        &__content {
            font-size: 0.9em;
            &--disabled {
                color: #bfbfbf;
            }
        }
        &--request-review {
            border-bottom: 1px solid #474747;
            padding-top: 0.5em;
        }
        &--mark-completed {
            padding-top: 0.9em;
        }
        // text-align: center;
    }
    &__header {
        max-width: 0.6em;
        position: absolute;
        right: -0.7em;
        top: -2.3em;
    }
}
