@import '../../styles/variables';
@import '../../styles/responsive';

.createauditschedule {
    &__viewBy {
        padding: 0;
        margin-top: 1em;
        &__dropdown {
            margin-top: 1rem;
            padding: 0;
            > div {
                > div {
                    border: none !important;
                    box-shadow: $boxShadowBase;
                    border-radius: $borderRadiusSecondary;
                    svg {
                        color: $colorBase;
                    }
                }
            }
        }
        > .switch-field {
            input:checked + label {
                background-color: $colorBase;
            }
            > label {
                color: $colorTextGhost;
                background-color: $colorWhite;
                border: none;
                box-shadow: $boxShadowBase;
                height: 3rem;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0;
                border-radius: 0;
                font-size: $fontSizeBase;
            }
        }
    }
    &__content {
        &__row {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            padding: 0 10px;
            @media #{$s360} {
                flex-direction: column;
            }
        }
        &__item {
            &.col {
                flex: 1 1;
                width: 100%;
                padding: 0 10px;
            }
            margin: 1rem 0;
            padding: 0 20px;
            //background-color: $colorWhite;
            &.bg-white {
                background-color: $colorWhite;
            }
            .pure-material-textfield-outlined {
                box-shadow: 0px 10px 20px rgba(120, 121, 147, 0.15);
                > input,
                textarea {
                    border-radius: $borderRadiusSecondary;
                    background-color: $colorWhite;
                    box-shadow: 0px 10px 20px rgba(120, 121, 147, 0.15);
                    border: none;
                    &:focus + span,
                    &:focus + span::before,
                    &:focus + span::after {
                        box-shadow: none;
                        border: none;
                        color: $colorBase;
                    }
                }
                > input + span::before,
                > input + span::after {
                    border: none;
                }
            }
        }
    }
    &__due-date {
        &__label {
            font-size: 14px;
            color: rgba(24, 24, 24, 0.4);
            margin-bottom: 0.5em;
        }
        &__picker {
            .react-datepicker-wrapper {
                display: block;
            }
            input {
                outline: none;
                width: 100%;
                font-size: 0.9em;
                border-radius: $borderRadiusSecondary;
                box-shadow: $boxShadowBase;
                border: none;
                line-height: 16px;
                padding: 15px 10px;
                margin: 0;
            }
        }
    }
    &__cta {
        margin: 1em 0;
    }
}
