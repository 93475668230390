.reports__category {
  &__item {
    background-color: #FFFFFF;
    &__content {
      width: 80%;
      margin: 2em auto 1em auto;
    }
  }
  &__compliance {
    h1 {
      padding: 1em 1.5em;
      border-bottom: 1px solid rgba(41, 42, 44, 0.08);
    }
    .compliance_item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1em 1.5em;
      border-bottom: 1px solid rgba(41, 42, 44, 0.08);
      img {
        max-width: 60px;
      }
      p {
        padding: 5px 0;
        margin: 0;
      }
    }
  }
  .no-items {
    text-align: center;
    color: grey;
  }
}