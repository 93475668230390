@import '../../styles/responsive';
@import '../../styles/variables';

.settings {
    &--loading {
      padding: 2em;
      margin: 0 auto;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;    
    }
    &__users {
        padding: 1em;
        @include screenMax576 {
            padding: 0;
        }
    }
}

.users {
    margin-top: 1em;
    &__title {
        font-size: .9em;
        font-weight: bold;
        margin-bottom: .5em;
    }
    &__list {
        &__empty {
            padding: 2em;
            text-align: center;
            color: #474747;
        }
    }
    &--pending, &--approved {
        @include screenMax576 {
            background-color: $colorWhite;
            padding: 1rem;
        }
    }
}



.userdetails {
    padding: .5em 0;
    &__personal {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: .3em;
        &__left {
            margin-right: .5em;
        }
    }
    &__name {
        margin-bottom: .1em;
        color: #313131;
        line-height: 1.2;
        font-size: .8em;
    }
    &__time {
        font-size: .6em;
        color: #474747;
    }
    &--pending, &--approved {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: .7em 0;
        border: 1px solid #313131;
        border-radius: 4px;
        padding: .5em;
        @include screenMax576 {
            border: none;
            box-shadow: $boxShadowBase;
            border-radius: $borderRadiusBase;
            padding: 1rem;
        }
    }
    &__cta {
        margin-left: .3em;
        max-width: 10em;
        > button.btn {
            border: 1px solid $colorDanger;
            min-width: 8rem;
            min-height: 2.5rem;
            border-radius: 4px;
            font-weight: 600;
        }
    }
    &--store {
        padding: .3em 1em;
        &__content {
            background-color: #27AEE3;
            color: #ffffff;
            font-size: .7em;
            border-radius: 4px;
            padding: 4px 6px;
        }
    }
}