.reportselect {
    &__content {
        padding: 1em;
    }
    &__cta {
        padding: 1em;
    }
    &__title {
        font-size: 1.3em;
        margin-bottom: 1em;
    }
}