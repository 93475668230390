@import "../../mixin.scss";
@import '../../styles/responsive';
@import '../../styles/variables';

.home {
    &__header {
        padding: 1em;
        display: flex;
        flex-direction: row;
        &__paragraph {
            &--greeting {
                font-size: 1.5em;
            }
            &--name {
                font-size: 1.5em;
            }
        }
    }
    &__content {
        align-items: center;
        text-align: center;
        @media(min-width: 991px){
            flex-direction: row;
        }
        flex-direction: column;
        justify-content: center;
        &--cta {
            margin: 1em 0;
        }
        @include screenMax576 {
            text-align: left;
        }
    }
    &__illustration {
        margin: 0 auto;
        padding: 1em;
        max-width: 15em;
        position: relative;
        &__client {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: absolute;
            top: 33%;
            left: 31%;
            &__logo {
                max-width: 4em;
            }
            &__name {
                font-size: .8rem;
            }
        }
        @include screenMax576 {
            display: none;
        }
    }
    &__cta {
        padding: .5em 1em;
        flex: 1 1;
        display: inline-flex;
        width: 33%;
        align-items: center;
        position: relative;
        @include screenMax576 {
            width: 50%;
        }
        button.btn {
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: #fff;
            min-width: auto;
            margin: auto;
            width: 100%;
            padding: 1.5rem 0;
            > svg {
                font-size: 2.5rem;
                color: $colorBase;
            }
        }
        &--badge {
            background-color: #fbd6d6;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            top: 1em;
            right: 25px;
            z-index: 999;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            color: black;
        }
        &--icon {
            width: 35px;
            height: 35px;
        }
    }
    &__logout {
        position: absolute;
        padding: 2em 1em;
        top: 0;
        margin-top: -70px;
        right: 0;
        &__link {
            display: inline-block;
            padding-bottom: 3px;
            color: #292A2C;
            border-bottom: 1px solid #292A2C;
        }
    }
}
