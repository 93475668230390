.taskactivities {
    &__item {
        &__image {
            max-width: 50%;
            margin: 0 auto;
            min-height: 10em;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #474747;
        }
        &__text {
            text-align: center;
            padding: 1em .5em;
        }
        &__description {
            font-size: .9em;
        }
        &__createdAt {
            font-size: .8em;
            color: #474747;
            padding: .3em .5em;
        }
    }
}

.slick-prev, .slick-next {
    &:before {
        color: #000000!important;
    }
}

.modal-hide {
    display: none;
}

.modal-show {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(255, 255, 255, 0.75);
}

.modal__close {
    max-width: 18px;
    margin-left: auto;
    z-index: 99999;
}

.TransformComponent-module_content__TZU5O {
    display: block !important;
}