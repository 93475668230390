@import '../../mixin.scss';
@import '../../styles/variables';
@import '../../styles/responsive';

// colors customized
$primary-color-blue: #30bae7;
@mixin transform($transforms) {
	-webkit-transform: $transforms;
	-moz-transform: $transforms;
	-ms-transform: $transforms;
	transform: $transforms;
}

// Rotate
@mixin rotate($deg) {
	@include transform(rotate(#{$deg}deg));
}

.reports {
	&__planogram_primary_heading_mobile {
		text-align: center;
		width: 100%;
		text-transform: capitalize;
		&_title {
			font-weight: 600;
		}
		&_period {
			font-size: 14px;
			margin-bottom: 2rem;
		}
	}
	&__planogram_primary_heading {
		text-align: center;
		width: 100%;
		@media (max-width: 799px) {
			display: none;
		}
	}
	&__viewBy {
		&__dropdown {
			margin-top: 1em;
			& > div > div {
				border: none !important;
				box-shadow: 0px 2px 30px #eae7e7;
				border-radius: 0.625rem;
				svg {
					color: #391eac;
				}
			}
		}
	}
	&__label {
		color: rgba(41, 42, 44, 0.4);
		font-size: 14px;
		text-align: left;
	}
	&__content {
		&__item {
			padding: 1em;
			&--all {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
			}
			&--none {
				padding: 3em;
				margin: 0 auto;
				color: #474747;
				font-size: 0.8em;
				text-align: center;
			}
			&--title {
				font-size: 1.4em;
				padding: 0.8em 0.6em;
				text-transform: capitalize;
			}
		}
		&__desktop {
			display: block;
			@include screenMax576 {
				display: none;
			}
		}
		&__mobile {
			display: none;
			@include screenMax576 {
				display: block;
			}
		}
	}
	&__controls {
		&.mobile {
			@media (min-width: 800px) {
				display: none;
			}
			margin: 0 auto;
		}
		&__datePicker {
			display: flex;
			justify-content: space-between;
			margin-bottom: 1.5em;
			&--item {
				background-color: white;
				border-radius: 10px;
				box-shadow: 0 10px 20px rgba(120, 121, 147, 0.0867584);
				padding: 10px 20px;
				width: 45%;
                height: 40px;
			}
		}
		&.desktop {
			@media (max-width: 799px) {
				display: none;
			}
			width: 100%;
			max-width: 100%;
			flex-direction: row;
			margin: 0 0.5em;
			& .reports__content {
				width: 100%;
			}
			& .reports__controls__item__title {
				margin: 0.8em;
			}
		}
		display: flex;
		flex-direction: column;
		max-width: 20.63em;
		&__item {
			padding: 0.5em;
			width: 100%;
			&__title {
				margin-bottom: 0.7em;
				font-size: 1em;
				font-weight: bold;
			}
		}
	}
	&__category {
		width: 100%;
		padding: 0.3em 0;
		&__item {
			padding: 0.8em 0;
			&__title {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				text-transform: capitalize;
				&__result {
					color: #474747;
					font-size: 0.9em;
				}
			}
			&__link {
				color: #000000;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: flex-end;
			}
			&__icon {
				max-width: 20px;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: row;
				margin-left: 5px;
			}
			&__content {
				flex: 1;
			}
			&__bar {
				width: 100%;
				background-color: #f2f2f2;
				border-radius: 4px;
				&__content {
					background-color: #27aee3;
					height: 10px;
					background-color: #27aee3;
					border-radius: 4px;
					margin-top: 0.3em;
				}
			}
		}
	}
	&__overall {
		max-width: 10em;
		margin: 0 auto;
		padding-bottom: 1em;
	}
}

// VARS
$circle-width: 0.09em;
$circle-width-hover: 0.07em;

// colors default
$primary-color: #000000; // czarny
$secondary-color: #dfe8ed; //szary bcg
$bg-color: #ffffff; //srodkowy bezowy

// colors customized
$primary-color-blue: #30bae7;
$primary-color-green: #15c7a8;
$primary-color-orange: #eb7d4b;
$primary-color-pink: #d74680;
$primary-color-span: #3c4761;

// CIRCLE
// classes 2 extend
.rect-auto {
	clip: rect(auto, auto, auto, auto);
}

.pie {
	position: absolute;
	border: $circle-width solid $primary-color;
	width: 1 - (2 * $circle-width);
	height: 1 - (2 * $circle-width);
	clip: rect(0em, 0.5em, 1em, 0em);
	border-radius: 50%;
	@include rotate(0deg);
}

.pie-fill {
	@include rotate(180deg);
}
.wrapper {
	width: 1200px;
	margin: 0 auto;
}

// main
.c100 {
	*,
	*:before,
	*:after {
		box-sizing: content-box;
	}

	position: relative;
	font-size: 160px;
	width: 1em;
	height: 1em;
	border-radius: 50%;
	float: left;
	margin: 0.4em;
	background-color: $secondary-color;

	// 	// center circle to its parent
	// 	&.center{
	// 		float: none;
	// 		margin: 0 auto;
	// 	}

	// 	&.small{
	// 		font-size: 80px;
	// 	}

	// centered value inside circle
	> span {
		position: absolute;
		width: 100%;
		z-index: 1;
		left: 0;
		top: 0;
		width: 5em;
		line-height: 5em;
		font-size: 0.2em;
		color: $primary-color-span;
		display: block;
		text-align: center;
		white-space: nowrap;
		transition-property: all;
		transition-duration: 0.2s;
		transition-timing-function: ease-out;
	}

	// background inside the circle
	&:after {
		position: absolute;
		top: $circle-width;
		left: $circle-width;
		display: block;
		content: ' ';
		border-radius: 50%;
		background-color: $bg-color;
		width: 1 - (2 * $circle-width);
		height: 1 - (2 * $circle-width);
		transition-property: all;
		transition-duration: 0.2s;
		transition-timing-function: ease-in;
	}

	// the slice (mask)
	.slice {
		position: absolute;
		width: 1em;
		height: 1em;
		clip: rect(0em, 1em, 1em, 0.5em);
	}

	// circle to show the status
	.bar {
		@extend .pie;
	}

	// loop to create all needed elements automatically
	@for $j from 51 through 100 {
		&.p#{$j} .slice {
			@extend .rect-auto;
		}

		&.p#{$j} .bar:after {
			@extend .pie-fill;
		}

		&.p#{$j} .fill {
			@extend .pie;
			@extend .pie-fill;
		}
	}

	// loop to rotate all 100 circles
	@for $j from 1 through 100 {
		&.p#{$j} .bar {
			@include rotate((360/100 * $j) + deg);
		}
	}

	// hover styles
	&:hover {
		cursor: default;

		> span {
			width: 3.33em;
			line-height: 3.33em;
			font-size: 0.3em;
			color: $primary-color-span;
		}

		&:after {
			top: $circle-width-hover;
			left: $circle-width-hover;
			width: 1 - (2 * $circle-width-hover);
			height: 1 - (2 * $circle-width-hover);
		}
	}

	// blue
	&.blue {
		.bar,
		.fill {
			border-color: $primary-color-blue !important;
		}

		&:hover {
			> span {
				color: $primary-color-span;
			}
		}
	}

	// pink skin
	&.pink {
		.bar,
		.fill {
			border-color: $primary-color-pink !important;
		}

		&:hover {
			> span {
				color: $primary-color-span;
			}
		}
	}

	// green skin
	&.green {
		.bar,
		.fill {
			border-color: $primary-color-green !important;
		}

		&:hover {
			> span {
				color: $primary-color-span;
			}
		}
	}

	// orange skin
	&.orange {
		.bar,
		.fill {
			border-color: $primary-color-orange !important;
		}

		&:hover {
			> span {
				color: $primary-color-span;
			}
		}
	}
}

.calendar_date_range_picker {
	position: absolute !important;
	left: 0;
	width: 100%;
	background-color: white;
	box-shadow: 0 2px 16px rgba(153, 155, 168, 0.25);
	padding: 1em 0 !important;
	.DateRangePicker__Month {
		margin: 0;
		width: 100%;
		.DateRangePicker__MonthDates {
			width: 90%;
			margin: 0 auto;
			border: none;
			.DateRangePicker__Date {
				border: none;
			}
			.DateRangePicker__CalendarSelection {
				background-color: rgba(57, 30, 172, 0.1);
				border: 1px solid #391eac;
				border-right: 0;
				border-left: 0;
			}
			.DateRangePicker__Date--is-selected {
				color: #000;
			}
			.DateRangePicker__CalendarSelection--start {
				border: 1px solid #391eac;
				border-right-color: transparent;
			}
			.DateRangePicker__CalendarSelection--end {
				border: 1px solid #391eac;
				border-left-color: transparent;
			}
			.DateRangePicker__CalendarSelection--single {
				border: 1px solid #391eac;
			}
		}
	}
	.DateRangePicker__PaginationArrow {
		margin-top: 1em;
		.DateRangePicker__PaginationArrowIcon--next {
			border: solid #391eac;
			border-width: 0 2px 2px 0;
			display: inline-block;
			padding: 3px;
			transform: rotate(-45deg);
			-webkit-transform: rotate(-45deg);
		}
		.DateRangePicker__PaginationArrowIcon--previous {
			border: solid #391eac;
			border-width: 0 2px 2px 0;
			display: inline-block;
			padding: 3px;
			transform: rotate(135deg);
			-webkit-transform: rotate(135deg);
		}
	}
}