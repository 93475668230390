@import '../../styles/variables';
@import '../../styles/responsive';

.createtask {
    &__viewBy {
        padding: 0;
        margin-top: 1em;
        &__dropdown {
            margin-top: 1rem;
            padding: 0 2rem;
            > div {
                > div {
                    border: none !important;
                    box-shadow: $boxShadowBase;
                    border-radius: $borderRadiusSecondary;
                    svg {
                        color: $colorBase;
                    }
                }
            }
        }
        > .switch-field {
            input:checked + label {
                background-color: $colorBase;
            }
            > label {
                color: $colorTextGhost;
                background-color: $colorWhite;
                border: none;
                box-shadow: $boxShadowBase;
                height: 3rem;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0;
                border-radius: 0;
                font-size: $fontSizeBase;
            }
        }
    }
    &__content {
        &__item {
            margin-top: 1rem;
            padding: 0 2rem;
            //background-color: $colorWhite;
            &.bg-white {
                background-color: $colorWhite;
            }
            &--time {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
                input {
                    font-size: 0.9em;
                    border-radius: 4px;
                    box-shadow: $boxShadowBase;
                    border: none;
                    line-height: 16px;
                    padding: 6px 10px 5px;
                    margin: 0.5em 0 1em 0;
                    min-width: 15em;
                }
            }
            .pure-material-textfield-outlined {
                box-shadow: 0px 10px 20px rgba(120, 121, 147, 0.15);
                > input,
                textarea {
                    border-radius: $borderRadiusSecondary;
                    background-color: $colorWhite;
                    box-shadow: 0px 10px 20px rgba(120, 121, 147, 0.15);
                    border: none;
                    &:focus + span,
                    &:focus + span::before,
                    &:focus + span::after {
                        box-shadow: none;
                        border: none;
                        color: $colorBase;
                    }
                }
                > input + span::before,
                > input + span::after {
                    border: none;
                }
                > textarea + span::before,
                > textarea + span::after {
                    border: none;
                }
            }
        }
    }
    &__file {
        margin-top: 0;
        &__preview {
            padding: 0.5em 0;
            max-width: 100%;
            margin: 0 auto;
        }
        &__button {
            box-shadow: none;
            background-color: $colorWhite;
            color: $colorBase;
            border: 2px solid $colorBase;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: $borderRadiusBase;
            font-weight: 600;
            min-height: 2rem;
        }
    }
    &__due-date {
        &__label {
            padding-top: 1rem;
        }
        &__picker {
            input {
                font-size: $fontSizeBase;
                border-radius: 4px;
                box-shadow: $borderRadiusBase;
                border: none;
                line-height: 16px;
                padding: 6px 10px 5px;
                margin: 0.5em 0 1em 0;
                min-width: 15em;
                &:focus {
                    outline: none;
                }
            }
        }
    }
    &__type {
        &__selector {
            margin-bottom: 0.5em;
        }
    }
    &__duration {
        &__picker {
            display: flex;
            align-items: center;
            input {
                min-width: unset;
                width: 8rem;
            }
        }
    }
    &__assign {
        &__label {}
        &__select {}
    }
}
