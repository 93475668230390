@import "../../mixin.scss";

// colors customized
$primary-color-blue: #30bae7;
@mixin transform($transforms) {
	-webkit-transform: $transforms;
	-moz-transform: $transforms;
	-ms-transform: $transforms;
	transform: $transforms;
}

// Rotate
@mixin rotate ($deg) { 
	@include transform(rotate(#{$deg}deg));
}

.reports {
    &__content {
        &__item {
            padding: 1em;
            &--all {
                display: flex;
                flex-direction: column;
            }
            &--none {
                padding: 3em;
                margin: 0 auto;
                color: #474747;
                font-size: .8em;
                text-align: center;
			}
			&--title {
				font-size: 1.4em;
				padding: .8em .6em;
			}
        }
	}
	&__controls {
		&.mobile {
			@media (min-width: 800px) {
				display: none;
			}
		}
		&.desktop {
			@media (max-width: 799px) {
				display: none;
			}
		}
		display: flex;
		flex-direction: column;
		margin: 0 .5em;
		max-width: 20.63em;
		&__item {
			padding: .5em;
			width: 100%;
			&__title {
				margin-bottom: .7em;
				font-size: 1em;
				font-weight: 500;
			}
		}
	}
    &__category {
		padding: .3em 0;
        &__item {
            padding: .8em 0;
            &__title {
                display: flex;
                flex-direction: row;
				justify-content: space-between;
				text-transform: capitalize;
				&__result {
					color: #474747;
					font-size: .9em;
				}
			}
			&__bar {
				width: 100%;
				background-color: #f2f2f2;
				border-radius: 4px;
				&__content {
					background-color: #27AEE3;
					height: 10px;
					background-color: #27AEE3;
					border-radius: 4px;
					margin-top: .3em;
				}
			}
        }
	}
	&__overall {
		max-width: 10em;
		margin: 0 auto;
		padding-bottom: 1em;
	}
}


// VARS
$circle-width: 0.09em;
$circle-width-hover: 0.07em;

// colors default
$primary-color: #000000; // czarny
$secondary-color: #dfe8ed; //szary bcg
$bg-color: #ffffff; //srodkowy bezowy 

// colors customized
$primary-color-blue: #30bae7;
$primary-color-green: #15c7a8;
$primary-color-orange: #eb7d4b; 
$primary-color-pink: #d74680; 
$primary-color-span: #3c4761;

// CIRCLE
// classes 2 extend
.rect-auto{
	clip: rect(auto, auto, auto, auto);
}

.pie {
	position: absolute;
	border: $circle-width solid $primary-color;
	width: 1 - (2 * $circle-width);
	height: 1 - (2 * $circle-width);
	clip: rect(0em, 0.5em, 1em, 0em);
	border-radius: 50%;
	@include rotate(0deg);
}

.pie-fill {
	@include rotate(180deg);
}
.wrapper {
  width: 1200px;
  margin: 0 auto;
}


// main
.c100 {

	*, *:before, *:after {
		box-sizing:content-box;
	}

	position: relative;
	font-size: 160px;
	width: 1em;
	height: 1em;
	border-radius: 50%;
	float: left;
	margin: 0.4em;
	background-color: $secondary-color;

// 	// center circle to its parent
// 	&.center{
// 		float: none;
// 		margin: 0 auto;
// 	}

// 	&.small{
// 		font-size: 80px;
// 	}

	// centered value inside circle
	> span {
		position: absolute;
		width: 100%;
		z-index: 1;
		left: 0;
		top: 0;
		width: 5em;
		line-height: 5em;
		font-size: 0.2em;
		color: $primary-color-span;
		display: block;
		text-align: center;
		white-space: nowrap;
		transition-property:all;
		transition-duration:0.2s;
		transition-timing-function:ease-out;
	}

	// background inside the circle
	&:after{
		position: absolute;
		top: $circle-width;
		left: $circle-width;
		display: block;
		content: " ";
		border-radius: 50%;
		background-color: $bg-color;
		width: 1 - (2 * $circle-width);
		height: 1 - (2 * $circle-width);
		transition-property:all;
		transition-duration:0.2s;
		transition-timing-function:ease-in;

	}

	// the slice (mask)
	.slice {
		position: absolute;
		width: 1em;
		height: 1em;
		clip: rect(0em, 1em, 1em, 0.5em);
	}

	// circle to show the status
	.bar {
		@extend .pie;
	}


	// loop to create all needed elements automatically
	@for $j from 51 through 100 {

		&.p#{$j} .slice {
			@extend .rect-auto;
		}

		&.p#{$j} .bar:after{
			@extend .pie-fill;
		}

		&.p#{$j} .fill{
			@extend .pie;
			@extend .pie-fill;
		}

	}

	// loop to rotate all 100 circles
	@for $j from 1 through 100 {
		&.p#{$j} .bar {
			@include rotate((360/100*$j) + deg);
		}
	}



	// hover styles
	&:hover{

		cursor: default;

		> span {
			width: 3.33em;
			line-height: 3.33em;
			font-size: 0.3em;
			color: $primary-color-span;
		}

		&:after{
			top: $circle-width-hover;
			left: $circle-width-hover;
			width: 1 - (2 * $circle-width-hover);
			height: 1 - (2 * $circle-width-hover);
		}

	}
  
	// blue
	&.blue{

		.bar, .fill { border-color: $primary-color-blue !important;}

		&:hover{
			> span { color: $primary-color-span;}
		}

	}


	// pink skin
	&.pink{

		.bar, .fill { border-color: $primary-color-pink !important;}

		&:hover{
			> span { color: $primary-color-span;}
		}

	}


	// green skin
	&.green{

		.bar, .fill { border-color: $primary-color-green !important;}

		&:hover{
			> span { color: $primary-color-span;}
		}

	}


	// orange skin
	&.orange{

		.bar, .fill { border-color: $primary-color-orange !important;}

		&:hover{
			> span { color: $primary-color-span;}
		}

	}

}