.pendingapproval {
    padding: 1em;
    margin: 0 auto;
    &__logo {
        max-width: 10em;
        margin: 0 auto;
        margin-top: 1em;
        margin-bottom: 2em;
    }
    &__illustration {
        margin-top: 1em;
        margin-bottom: 2em;
        max-width: 20em;
    }
    &__cta {
        margin: 1em 0;
    }
}