.emailnotverified {
    padding: 1em;
    &__logo {
        max-width: 10em;
        margin: 0 auto;
        margin-top: 1em;
        margin-bottom: 2em;
    }
    &__illustration {
        margin-top: 1em;
        margin-bottom: 2em;
        max-width: 20em;
        margin: 0 auto;
    }
    .content {
        text-align: center;
    }
}