.registration {
    max-width: 30em;
    margin: 0 auto;
    &__content {
        &__item {
            margin: 1em;
            &__title {
              &--selectrole {
                  font-weight: 700;
                  font-size: .9em;
              }
            }
        }
    }
    &__cta {
        margin: 2em 1em;
    }
}


$color-chip: linear-gradient(90deg, #D0A963, #FFE6BA);
$color-card--blue: linear-gradient(45deg, #748DFB, #3859E8);

$bubble-d: 4rem;
$bubble-r: .5*$bubble-d;
$sparkle-d: .375rem;
$sparkle-r: .5*$sparkle-d;


@mixin bubble($ext) {
	transform: scale(1);
	border-color: #545461;
	border-width: $ext;
}

[type="radio"] {
  z-index: -1;
  position: absolute;
  opacity: 0;
  
  &:checked ~ .payment-label {
    border-color: #27AEE3;
    background-color: rgba(#619AEA, 0.16);
		
		&:before {
			will-change: transform, border-width, border-color;
            animation: bubble 1s ease-in;
		}
    
    &:after {
			will-change: opacity, box-shadow;
			animation: sparkles 700ms ease-in-out;
		}
    
    > .payment-label__content--left .payment-label__radio .payment-label__circle {
      will-change: transform;
      border: 0;
      background-image: linear-gradient(to top right, #6E89FF, #4363EE);
      animation: radio 400ms cubic-bezier(.17, .89, .32, 1.49);
      
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 6px;
        height: 6px;
        border-radius: 10px;
        background-color: #fff;
      }
    }
    
    .card {
      will-change: box-shadow;
      animation: card 500ms ease-in-out forwards;
      
      &:after {
        will-change: transform;
        animation: shine 500ms ease-in forwards;
        animation-delay: 100ms;
      }
    }
  }
}

.payment {
  margin: 1em 0;
}

.payment-label {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
//   grid-template-columns: 20px auto 100px;
//   grid-gap: 20px;
//   max-width: 320px;
//   height: 62px;
  padding: .5em;
  
  border-radius: 6px;
  border: 2px solid transparent;
  background-color: transparent;
  transition: all 300ms ease-in;
  
  &:hover {
    border-color: #27AEE3;
    background-color: rgba(#619AEA, 0.16);
  }
  
  &:before,
  &:after {
		position: absolute;
    left: 29px;
		border-radius: 50%;
		content: '';
	}
  
    
   &__circle {
    position: relative;
    display: inline-flex;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    border: 3px solid #454861;
    background-image: linear-gradient(to bottom, #f2f2f2, #f2f2f2);
  }
  &__content {
      &--left {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          flex: 1;
      }
  }
  &__text {
      padding: 0 .5em;
      &-title {
          color: #1f8dce;
          font-weight: 500;
          padding-bottom: .2em;
      }
      &-subtitle {
          font-size: .8em;
      }
  }
  &__icon {
      margin: .5em 1em;
      max-width: 3em;
  }
}

.card {
  position: relative;
  width: 243px;
  height: 152px;
  padding: 22px 24px;
  border-radius: 16px;
  box-shadow: 0 1px 0 0 rgba(#fff, 0.25);
  overflow: hidden;
  
  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 40px;
    transform: translateX(-70px);
    background-image: linear-gradient(to right, rgba(#fff, 0), rgba(#fff, 0.2), rgba(#fff, 0));
  }
  
  &--blue {
    background-image: $color-card--blue;
  }
  
  &--cod {
    &:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
  
  &--sm {
    position: absolute;
    right: -76px;
    transform: scale(0.24);
  }
  
  &__chip {
    width: 39px;
    height: 28px;
    border-radius: 7px;
    border: 1px solid rgba(#665432, 0.5);
    box-shadow: inset 1px 1px 0px rgba(#B39256, 0.5);
    background-image: $color-chip;
  }
  
  &__content {
    display: flex;
    justify-content: space-between;
    margin-top: 46px;
  }
  
  &__symbol {
    display: flex;
    
    span {
      display: block;
      width: 30px;
      height: 30px;
      border-radius: 30px;
      background-color: #FB4646;
      
      &:last-child {
        background-color: rgba(#FFA337, 0.75);
        margin-left: -13px;
      }
    }
  }
}

.text {
  &__row {
    display: grid;
    grid-template-columns: 54px 64px;
    grid-gap: 6px;
    
    &:last-of-type {
      grid-template-columns: 45px 54px;
      margin-top: 7px;
    }
  }
  
  &__loader {
    height: 13px;
    border-radius: 2px;
    background-color: rgba(#000, 0.4);
  }
}

.option {
    border: 1px solid #27AEE3;
    border-radius: 4px;
}

.option:not(:last-child) {
  margin-bottom: 1em;
}

@keyframes radio {
	0%, 17.5% { transform: scale(0); }
}

@keyframes card {
  0% { box-shadow: 0 1px 0 0 rgba(#fff, 0.25); transform: scale(0.24) }
  45% { box-shadow: 0 12px 32px 0 rgba(#000, 0.5); transform: scale(0.25) }
  100% { box-shadow: 0 4px 12px 0 rgba(#000, 0.4); transform: scale(0.24) }
}

@keyframes shine {
  from { transform: translateX(-70px) rotate(10deg); }
  to { transform: translateX(300px) rotate(10deg); }
}


