.createpromotiontask {
    max-width: 40em;
    &__content {
        &__item {
            padding: .3em 1em;
            &--time {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
                input {
                    font-size: .9em;
                    border-radius: 4px;
                    box-shadow: inset 0 2px 2px #e9e9e9;
                    border: 1px solid #aeaeae;
                    line-height: 16px;
                    padding: 6px 10px 5px;
                    margin: .5em 0 1em 0;
                    min-width: 15em;
                }
            }
        }
    }
    &__file {
        &__preview {
            padding: .5em 0;
            max-width: 100%;
            margin: 0 auto;
        }
        &__button {
            box-shadow: none;
        }
    }
    &__due-date {
        &__picker {
            input {
                font-size: .9em;
                border-radius: 4px;
                box-shadow: inset 0 2px 2px #e9e9e9;
                border: 1px solid #aeaeae;
                line-height: 16px;
                padding: 6px 10px 5px;
                margin: .5em 0 1em 0;
                min-width: 15em;
            }
        }
    }
    
}